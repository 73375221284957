var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pr-0 pt-4 pb-0",attrs:{"cols":"12"}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-4 pt-0"},[_c('v-container',[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.toggleFiltersSelected($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"return-object":"","items":_vm.conceptos,"label":"Concepto","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.conceptoSelected),callback:function ($$v) {_vm.conceptoSelected=$$v},expression:"conceptoSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Período","outlined":"","rules":_vm.rules.periodoYyyyMm,"dense":"","hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodoFilter),callback:function ($$v) {_vm.periodoFilter=$$v},expression:"periodoFilter"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":"Porcentaje","outlined":"","dense":"","rules":_vm.rules.required.concat(
                        _vm.rules.nonZero,
                        _vm.rules.maxDigitsDecimal(4, 2)
                      ),"appendIcon":_vm.porcentajeIcon},model:{value:(_vm.valorAumento),callback:function ($$v) {_vm.valorAumento=$$v},expression:"valorAumento"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","clearable":"","items":_vm.redondeoItems,"item-text":"value","item-value":"id","rules":_vm.rules.required,"label":"Redondeo","return-object":""},model:{value:(_vm.redondeoSelect),callback:function ($$v) {_vm.redondeoSelect=$$v},expression:"redondeoSelect"}})],1)],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.procesosItems,"loading":_vm.loading,"search":_vm.search,"item-key":"adicionalId","show-select":""},on:{"toggle-select-all":function($event){return _vm.toggleSelectedItems()}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","align-self":"center"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.data-table-select",fn:function(ref){
                var item = ref.item;
                var isSelected = ref.isSelected;
                var select = ref.select;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"value":isSelected,"readonly":item.registroDuplicado,"disabled":item.registroDuplicado,"color":"primary","ripple":false},on:{"input":function($event){return select($event)}}},'v-simple-checkbox',attrs,false),on))]}}],null,true)},[(item.registroDuplicado)?_c('span',[_vm._v("Registro duplicado. No se puede seleccionar")]):_c('span',[_vm._v("Seleccionar registro")])])]}},{key:"item.benGrId",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.registroDuplicado ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.benGrId))])]}},{key:"item.afiliadoTit",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.registroDuplicado ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.afiliadoTit))])]}},{key:"item.concepto.value",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.registroDuplicado ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.concepto.value))])]}},{key:"item.periodoDesde",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.registroDuplicado ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.periodoDesde))])]}},{key:"item.periodoHasta",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.registroDuplicado ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.periodoHasta))])]}},{key:"item.importeToShow",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.registroDuplicado ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.importeToShow))])]}},{key:"item.nuevoImporteToShow",fn:function(ref){
                var item = ref.item;
return [_c('span',{style:(item.registroDuplicado ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.nuevoImporteToShow))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.registroDuplicado},on:{"click":function($event){return _vm.openModalEditImporteFijo(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar importe nuevo")])]):_vm._e()]}}],null,true),model:{value:(_vm.registrosSeleccionados),callback:function ($$v) {_vm.registrosSeleccionados=$$v},expression:"registrosSeleccionados"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[(_vm.procesosItems.find(function (x) { return x.registroDuplicado; }))?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *Existen grupos familiares con dos o más titulares. ")]):_vm._e()])],1),_c('v-card',{staticClass:"mt-2"},[_c('v-form',{ref:"actualizacion-form",attrs:{"id":"actualizacion-form"},on:{"submit":function($event){$event.preventDefault();return _vm.nuevoProcesoActualizacion()}},model:{value:(_vm.actualizacionValida),callback:function ($$v) {_vm.actualizacionValida=$$v},expression:"actualizacionValida"}},[_c('v-card',{attrs:{"loading":_vm.loadingProceso}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}})],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"primary--text pb-0"},[_vm._v(" Datos para el aumento")])],1),_c('v-col',{staticClass:"pl-8 pb-0 pt-7",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Período desde","outlined":"","dense":"","hint":"Formato AAAAMM","rules":_vm.rules.required.concat(
                      [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                      _vm.rules.periodoYyyyMm
                    ),"persistent-hint":""},model:{value:(_vm.periodoDesde),callback:function ($$v) {_vm.periodoDesde=$$v},expression:"periodoDesde"}})],1),_c('v-col',{staticClass:"pb-0 pt-7",attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Período hasta","outlined":"","dense":"","hint":"Formato AAAAMM","rules":_vm.rules.required.concat(
                      [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                      _vm.rules.periodoYyyyMm
                    ),"persistent-hint":""},model:{value:(_vm.periodoHasta),callback:function ($$v) {_vm.periodoHasta=$$v},expression:"periodoHasta"}})],1),_c('v-col',{staticClass:"pt-0 pb-2 pr-8 text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.actualizacionValida || _vm.loadingProceso,"elevation":"2","type":"submit","form":"actualizacion-form"}},[_vm._v(" Procesar ")])],1)],1)],2)],1)],1)],1)],1),(_vm.openEditImporteFijo)?_c('v-dialog',{attrs:{"max-width":"400px","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openEditImporteFijo),callback:function ($$v) {_vm.openEditImporteFijo=$$v},expression:"openEditImporteFijo"}},[_c('EditImporteNvoProcActualizacionImpFijos',{attrs:{"importeFijoToEdit":_vm.itemToEdit},on:{"closeAndUpdate":_vm.closeAndUpdate,"closeAndReload":_vm.closeAndReload}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }