<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="closeToEdit()"
        >
          <v-row>
            <v-col cols="7" class="py-0">
              <v-text-field
                v-model="importeNuevo"
                label="Importe nuevo"
                outlined
                dense
                :rules="
                  rules.required.concat(
                    rules.nonZero,
                    rules.maxDigitsDecimal(7, 2)
                  )
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";
export default {
  name: "EditEspecialidades",
  directives: { mask },
  props: {
    importeFijoToEdit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rules: rules,
      formEditTitle: "Editar importe nuevo",
      importeNuevo: null,
      isFormValid: false,
    };
  },
  created() {
    this.setImporteNuevo();
  },
  methods: {
    setImporteNuevo() {
      this.importeNuevo = this.importeFijoToEdit.nuevoImporteToEdit;
    },
    closeToEdit() {
      this.isFormValid = false;
      //valido primero si el usuario coloco un numero que empiece por . (.99)
      if (typeof this.importeNuevo === "string" && this.importeNuevo.charAt(0) === ".") {
        this.importeNuevo = `0${this.importeNuevo}`;
      } else {
        //Si usuario ingresa un entero, le coloco el 00 como decimal (visual en la tabla)
        this.importeNuevo = parseFloat(this.importeNuevo);
        if (Number.isInteger(this.importeNuevo)) {
          this.importeNuevo = `${this.importeNuevo}.00`;
        }
      }
      const data = {
        idProceso: this.importeFijoToEdit.adicionalId,
        importeViejo: this.importeFijoToEdit.nuevoImporteToEdit,
        importeNuevo: this.importeNuevo,
      };
      this.$emit("closeAndUpdate", data);
      this.isFormValid = true;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>